<template>
    <div v-infinite-scroll="loadMore" infinite-scroll-distance="25">
        <div v-if="isFetching" class="l-padded">
            <VSpinner size="medium" :line-fg-color="spinnerColor" :speed="1" />
        </div>

        <div
            v-else-if="!usersFiltered.length"
            class="l-padded l-inline l-center t-subtle t-small"
        >
            <span>
                {{ $t('shared.noSearchResult') }}
            </span>
        </div>

        <table v-else>
            <thead>
                <tr>
                    <th>
                        {{ $t('username') }}

                        <IconButton @click="handleSort('username')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'username' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('firstName') }}

                        <IconButton @click="handleSort('first_name')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'first_name' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('lastName') }}

                        <IconButton @click="handleSort('last_name')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'last_name' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('platform') }}

                        <IconButton @click="handleSort('platform')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'platform' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('language') }}

                        <IconButton @click="handleSort('language')">
                            <SortArrowIcon
                                :direction="
                                    sortBy === 'language' ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </IconButton>
                    </th>

                    <th>
                        {{ $t('admin') }}
                    </th>

                    <th>
                        {{ $t('actions') }}
                    </th>
                </tr>
            </thead>

            <template v-for="item in usersPortion">
                <tr :key="item.id">
                    <td>
                        {{ item.username }}
                    </td>

                    <td>
                        {{ item.first_name }}
                    </td>

                    <td>
                        {{ item.last_name }}
                    </td>

                    <td>
                        {{ item.platform }}
                    </td>

                    <td>
                        <template v-if="item.language">
                            {{ $t(`shared.language.${item.language}`) }}
                        </template>
                    </td>

                    <td>
                        <template v-if="item.is_account_admin">
                            {{ $t('shared.yes') }}
                        </template>
                    </td>

                    <td>
                        <div class="l-inline l-gap-1">
                            <IconButton
                                :title="$t('resetPassword')"
                                @click="handlePasswordReset(item)"
                            >
                                <PasswordResetIcon />
                            </IconButton>

                            <IconButton
                                :title="$t('delete')"
                                @click="handleDelete(item)"
                            >
                                <TrashBinIcon />
                            </IconButton>
                        </div>
                    </td>
                </tr>
            </template>
        </table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import infiniteScroll from 'vue-infinite-scroll'
import moment from 'moment-timezone'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'
import { commonService } from '@/service/store.service'
import IconButton from '@/components/IconButton'
import PasswordResetIcon from '@/components/icons/PasswordResetIcon'
import SortArrowIcon from '@/components/icons/SortArrowIcon'
import TrashBinIcon from '@/components/icons/TrashBinIcon'

const defaultScrollLimit = 15
const defaultSortColumn = 'username'
const defaultSortDirection = 1

export default {
    name: 'UsersTable',
    components: {
        IconButton,
        PasswordResetIcon,
        SortArrowIcon,
        TrashBinIcon,
        VSpinner,
    },
    directives: {
        infiniteScroll,
    },
    props: {
        filterName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isFetching: false,
            scrollLimit: defaultScrollLimit,
            scrollStep: defaultScrollLimit,
            sortBy: defaultSortColumn,
            sortDirection: defaultSortDirection,
            spinnerColor: process.env.VUE_APP_COLOR_PRIMARY,
        }
    },
    computed: {
        ...mapState('user', ['users']),
        usersFiltered() {
            let result = this.users

            if (this.filterName) {
                const query = this.filterName.toLowerCase()
                result = result.filter(
                    item =>
                        item.username.toLowerCase().includes(query) ||
                        item.first_name.toLowerCase().includes(query) ||
                        item.last_name.toLowerCase().includes(query)
                )
            }

            if (this.sortBy) {
                result = [...result].sort((a, b) => {
                    const aValue = a[this.sortBy]
                    const bValue = b[this.sortBy]

                    if (aValue === bValue) {
                        return 0
                    } else if (aValue == null) {
                        return 1
                    } else if (bValue == null) {
                        return -1
                    } else if (typeof aValue === 'string') {
                        return aValue.localeCompare(bValue) * this.sortDirection
                    }

                    return aValue > bValue
                        ? this.sortDirection
                        : -this.sortDirection
                })
            }

            return result
        },
        usersPortion() {
            return this.usersFiltered.length === this.users.length
                ? this.usersFiltered.slice(0, this.scrollLimit)
                : this.usersFiltered
        },
    },
    methods: {
        async fetchUsers() {
            this.isFetching = true
            await commonService.populateUsers()
            this.isFetching = false
        },
        formatTimestamp(timestamp) {
            return moment(timestamp).format('DD.MM.YYYY HH:mm')
        },
        async handlePasswordReset(user) {
            await httpHelper.post('/password-reset/', {
                email: user.email,
            })
            this.$notify(this.$t('passwordResetNotification'))
        },
        async handleDelete(user) {
            this.isFetching = true
            await httpHelper.drop(`/users/${user.id}/`)
            this.fetchUsers()
        },
        handleSort(column) {
            if (this.sortBy !== column || this.sortDirection !== 1) {
                this.sortBy = column
                this.sortDirection = 1
            } else if (this.sortDirection === 1) {
                this.sortDirection = -1
            }
        },
        loadMore() {
            if (this.scrollLimit < this.users.length) {
                this.scrollLimit += this.scrollStep
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "actions": "Actions",
        "admin": "Admin",
        "delete": "Delete",
        "firstName": "First Name",
        "language": "Language",
        "lastName": "Last Name",
        "passwordResetNotification": "Password has been reset",
        "platform": "Platform",
        "resetPassword": "Reset password",
        "username": "Username"
    },
    "de": {
        "actions": "Aktionen",
        "admin": "Admin",
        "delete": "Löschen",
        "firstName": "Vorname",
        "language": "Sprache",
        "lastName": "Nachname",
        "passwordResetNotification": "Passwort wurde zurückgesetzt",
        "platform": "Platform",
        "resetPassword": "Passwort zurücksetzen",
        "username": "Benutzername"
    },
    "fr": {
        "actions": "Actions",
        "admin": "Administrateur",
        "delete": "Supprimer",
        "firstName": "Prénom",
        "language": "Langue",
        "lastName": "Nom",
        "passwordResetNotification": "Mot de passe réinitialisé",
        "platform": "Platform",
        "resetPassword": "Réinitialiser le mot de passe",
        "username": "Nom d'utilisateur"
    },
    "it": {
        "actions": "Azione",
        "admin": "Amministratore",
        "delete": "Cancellare",
        "firstName": "Prenome",
        "language": "Lingue",
        "lastName": "Cognome",
        "passwordResetNotification": "La password è stata reimpostata",
        "platform": "Platform",
        "resetPassword": "Reimpostare la password",
        "username": "Nome utente"
    }
}
</i18n>

<style lang="scss" scoped>
table {
    display: block;
    width: 100%;
    border-spacing: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);

    thead {
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;

        .icon-button {
            margin-left: 5px;
        }
    }

    tr {
        &:not(:first-of-type) {
            td {
                border-top: $style-border;
            }
        }

        th {
            text-align: left;
            border-bottom: $style-border;
        }

        th,
        td {
            padding: 1rem;
            white-space: nowrap;

            &:first-child {
                padding-left: 2rem;
            }

            &:last-child {
                padding-right: 2rem;
            }

            &:not(:last-child) {
                width: 1%;
            }
        }
    }

    a {
        display: block;

        .icon-button {
            display: block;
        }
    }
}
</style>
